// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const TOGGLES = document.querySelectorAll(".contractor-filter__input[aria-controls]");

TOGGLES.forEach((toggle) => {
    const TARGET   = document.getElementById(toggle.getAttribute("aria-controls"));
    const CHILDREN = TARGET.querySelectorAll(".contractor-filter__input[type=checkbox]");

    toggle.addEventListener("change", () => {
        TARGET.toggleAttribute("hidden", ! toggle.checked);

        CHILDREN.forEach((child) => {
            child.checked = toggle.checked;
        });
    });
});
