// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper, { Pagination } from "swiper";

const SLIDESHOW = document.querySelector(".swiper-container");

// init swiper
if (SLIDESHOW) {
    new Swiper(SLIDESHOW, {
        autoplay: {
            delay: 15000,
        },
        loop: true,
        modules: [ Pagination ],
        pagination: {
            el: SLIDESHOW.querySelector(".swiper-pagination"),
            clickable: true,
        },
        speed: 150,
    });
}
